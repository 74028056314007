import React, { useState, useEffect } from "react";
import axios from "axios";

export default ({ setLoaded }) => {
  const imgEndpoint = "https://aws.random.cat/meow";
  const [image, setImage] = useState(false);

  useEffect(() => {
    if (!image) {
      axios.get(imgEndpoint).then(res => {
        if (res.status === 200) {
          setImage(res.data.file);
        }
      });
    }
  }, [image]);

  if (!image) return <div />;

  return <img src={image} onLoad={() => setLoaded(true)} alt="cat" />;
};
