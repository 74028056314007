import React, { useState } from "react";
import ReactDOM from "react-dom";

import "antd/dist/antd.css";
import "./styles.css";

import Animal from "./components/animal";

import { Row, Col, Typography, Button } from "antd";
const { Title } = Typography;

function App() {
  const [amount, setAmount] = useState([1, 2, 3]);

  const rng = () => {
    return Math.floor(Math.random() * 100000);
  };

  return (
    <div id="app">
      <div style={{ marginBottom: "6vh", paddingTop: "6vh" }}>
        <Title style={{ padding: "0 25px" }}>
          Generador de cosas bonitas de AliciaOC
        </Title>
      </div>

      <Row
        type="flex"
        justify="center"
        style={{ width: "100%", textAlign: "center", marginBottom: "10vh" }}
      >
        <Col style={{ marginBottom: "2vh" }} xs={24} sm={12}>
          {amount.map(i => (
            <Animal key={i} />
          ))}

          <div
            style={{
              textAlign: "center"
            }}
          >
            <Button
              type="primary"
              onClick={() => setAmount([...amount, ...[rng(), rng(), rng()]])}
            >
              ¡Quiero más
              <span
                role="img"
                aria-label="doggo"
                style={{ paddingLeft: ".5vw" }}
              >
                🐶
              </span>
              !
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
