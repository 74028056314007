import React, { useState } from "react";

import { Spin } from "antd";

import Dog from "./dog";
import Cat from "./cat";

export default () => {
  const [loaded, setLoaded] = useState(false);
  const [random] = useState(Math.random());

  return (
    <div style={{ marginBottom: "5vh" }}>
      <Spin spinning={!loaded} style={{ minHeight: "50px" }}>
        {random < 0.5 && <Dog setLoaded={setLoaded} />}
        {random >= 0.5 && <Cat setLoaded={setLoaded} />}
      </Spin>
    </div>
  );
};
